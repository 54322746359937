import { USER_ROLE, UserResponse } from '../providers/User/User.model';

function startIntercom(user: UserResponse | undefined): void {
  let userData = {};

  if (user) {
    userData = {
      user_id: user.id,
      name: `${user.first_name} ${user.last_name}`,
      email: user.email_address,
      company: {
        id: user.tenant?.id,
        name: user.tenant?.company_name
      },
      user_hash: user.intercom_user_hash
    };
  }

  window.Intercom('boot', { app_id: 'vdunphop', ...userData });
}

export function hideWidget(): void {
  window.Intercom('shutdown');
}

export function showWidget(enableIntercomFlag: boolean, user: UserResponse | undefined): void {
  const userRole = user?.role;

  if (!userRole || userRole === USER_ROLE.CUSTOMER_CONTACT) return;
  if (enableIntercomFlag) {
    startIntercom(user);
  } else return;
}
