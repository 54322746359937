export enum NavigationPanel {
  Dashboard = 'DASHBOARD',
  Orders = 'ORDERS',
  Trips = 'TRIPS',
  Loads = 'LOADS',
  Documents = 'DOCUMENTS',
  Companies = 'COMPANIES',
  Team = 'TEAM',
  Equipment = 'EQUIPMENT',
  Locations = 'LOCATIONS',
  Invoices = 'INVOICES',
  Reports = 'REPORTS',
  Settlements = 'SETTLEMENTS',
  Fuel = 'FUEL',
  Maintenance = 'MAINTENANCE',
  OtherExpenses = 'OTHER_EXPENSES',
  None = ''
}

export enum LoadsTab {
  ACTIVE_LOADS = 'Active loads',
  ALL_LOADS = 'All loads',
  CALENDAR = 'Calendar',
  NONE = ''
}

export enum TripsTab {
  ACTIVE_TRIPS = 'Board',
  ALL_TRIPS = 'Table',
  CALENDAR = 'Calendar'
}

export enum MobileNavItems {
  ORDERS = 'Orders',
  INBOX = 'Inbox',
  ACTIVE_TRIPS = 'Board'
}

export enum PinnableTabs {
  ORDERS = 'ORDERS',
  INBOX = 'INBOX',
  TRIPS = 'TRIPS',
  CALENDAR = 'CALENDAR',
  ACTIVE_TRIPS = 'ACTIVE_TRIPS',
  AVAILABILITY = 'AVAILABILITY'
}

export const ALLOWED_MOBILE_NAV_ITEMS_REFRESH_ICON = [
  MobileNavItems.ORDERS,
  MobileNavItems.ACTIVE_TRIPS
];
