import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AvailabilitySearchRequest,
  AvailabilitySearchState,
  AvailabilityStatus,
  AvailabilityType,
  LocationQuery
} from '../../api/availability/availability.model';
import { isEqual } from 'lodash';

const initialState: {
  selectedEntityId?: string | null;
  hoveredEntityId?: string | null;
} & AvailabilitySearchState = {
  type: AvailabilityType.truck,
  entities: [],
  status: undefined,
  start_date: '',
  end_date: '',
  last_completed_stop_location_queries: [],
  next_stop_location_queries: [],
  final_stop_location_queries: [],
  current_order_id: '',
  final_order_id: '',
  selectedEntityId: undefined,
  hoveredEntityId: undefined
};

const availabilitySlice = createSlice({
  name: 'availability',
  initialState,
  reducers: {
    setFilters(state, action: PayloadAction<Partial<AvailabilitySearchState>>) {
      const newState = {
        ...state,
        ...action.payload
      };

      if (!isEqual(state, newState)) {
        return newState;
      }
      return state;
    },
    resetFilters(state) {
      return initialState;
    },

    setSelectedEntityId(state, action: PayloadAction<string | null>) {
      state.selectedEntityId = action.payload === state.selectedEntityId ? null : action.payload;
    },
    setHoveredEntityId(state, action: PayloadAction<string>) {
      state.hoveredEntityId = action.payload;
    }
  }
});

export const { setFilters, resetFilters, setSelectedEntityId, setHoveredEntityId } =
  availabilitySlice.actions;
export default availabilitySlice.reducer;
